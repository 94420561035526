<template>
  <top-agent-self-card-use v-if="this.$store.getters.userRole === 'top_agent'" />
  <agency-self-card-use v-else-if="this.$store.getters.userRole === 'agency'" />
  <merchant-self-card-use v-else-if="this.$store.getters.userRole === 'merchant'" />
</template>

<script>

export default {
  name: 'ShowCardUse',
  components: {
    TopAgentSelfCardUse: () => import('@/views/agent_monthly_bills/card_use/top_agent_self/show/index'),
    AgencySelfCardUse: () => import('@/views/agent_monthly_bills/card_use/agency_self/show/index'),
    MerchantSelfCardUse: () => import('@/views/agent_monthly_bills/card_use/agency_self/show/index')
  }
}
</script>
